<template>
  <Main>
    <div v-if="contestData?.attributes" class="trading-account">
      <sdPageHeader class="trading__header">
        <template v-slot:title>
          <div class="account__wrapper">
            <p class="account__title">
              {{ contestData.attributes.title }}
            </p>
            <Status
              :status="contestData.attributes.status"
              :text="i18n.t('contests.statuses.' + contestData.attributes.status)"
              v-if="!isPc"
            />
          </div>
        </template>
        <template v-slot:buttons>
          <div class="account__wrapper">
            <p class="account__subtitle" v-if="isPc">
              <Status
                :status="contestData.attributes.status"
                :text="i18n.t('contests.statuses.' + contestData.attributes.status)"
              />
            </p>
            <p class="account__subtitle">
              <span>
                {{ i18n.t('contests.page.start_at') }}:
                <br />
              </span>
              {{ i18n.d(contestData.attributes.start_at, 'contest') }}
            </p>
            <p class="account__subtitle">
              <span>
                {{ `${i18n.t('contests.page.duration')}` }}
                <br />
              </span>
              {{ contestData.attributes.duration }}
              {{ i18n.t('contests.page.days') }}
            </p>

            <p class="account__subtitle">
              <span>
                {{ `${i18n.t('contests.page.leverage')}` }}
                <br />
              </span>
              1:{{ contestData.attributes.leverage }}
            </p>

            <p class="account__subtitle">
              <span>
                {{ `${i18n.t('contests.page.prize')}` }}
                <br />
              </span>
              {{ i18n.n(+contestData.attributes.amount, 'decimal') }}
              <span>{{ contestData.attributes.amount_currency }}</span>
            </p>
            <div class="account__buttons">
              <sdButton class="account__button" @click="modalChangeActivity" size="large" type="primary" raised>
                {{ i18n.t('contests.page.participate') }}
              </sdButton>
            </div>
          </div>
        </template>
      </sdPageHeader>

      <TableWrapper class="table-data-view table-responsive" v-if="membersData">
        <a-table :dataSource="dataSourceTransactions" :pagination="false" :columns="columnsTransactions"> </a-table>
        <!-- eslint-disable-next-line -->
        <div style="text-align: right; margin-bottom: 20px">
          <a-pagination
            :default-page-size="pageSize"
            v-model:pageSize="pageSize"
            :show-size-changer="true"
            v-model:current="membersMeta.current"
            :total="membersMeta.count"
            @change="onChangePage"
          >
            <template #buildOptionText="props">
              <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
            </template>
          </a-pagination>
        </div>
      </TableWrapper>
      <sdCards headless v-else>
        <a-skeleton active :paragraph="{ rows: 10 }" />
      </sdCards>
    </div>

    <a-spin v-else class="loading" size="large" />

    <PerticipateContestModal
      :contest="contestData"
      :visible="isModalActive"
      @closeModal="modalChangeActivity"
      v-model:buyTraitVisible="buyTraitModalVisible"
    />

    <BuyTrait :traits="contestData?.attributes.attempts_count" v-model:buyTraitVisible="buyTraitModalVisible" />
  </Main>
</template>

<script>
import { Main, TableWrapper } from '../styled';
import { useI18n } from 'vue-i18n';
import { onMounted, computed, defineAsyncComponent, ref } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
const Status = defineAsyncComponent(() => import('@/components/elements/Status'));
const PerticipateContestModal = defineAsyncComponent(() => import('./overview/PerticipateContestModal'));

const TradingAccountInfoPage = {
  name: 'TradingAccountInfoPage',
  components: {
    Main,
    TableWrapper,
    Status,
    PerticipateContestModal,
  },
  setup() {
    const { state, dispatch } = useStore();
    const route = useRoute();
    const i18n = useI18n();

    const linkValue = ref('id');
    const order = ref('desc');
    const pageSize = ref(50);
    const isModalActive = ref(false);
    const buyTraitModalVisible = ref(false);

    const contestData = computed(() => state.contests.contestData);
    const membersData = computed(() => state.contests.membersData);
    const membersMeta = computed(() => state.contests.membersMeta);
    const pageNum = computed(() => route.query.page);
    const getableData = computed(() => {
      return {
        id: route.params.id,
        pageNum: 1,
        pageSize: pageSize.value,
        sortableLink: `&sort_by=${linkValue.value}&order=${order.value}`,
      };
    });

    const onChangePage = (page) => {
      getableData.value.pageNum = page;
      dispatch('axiosMembersGetData', getableData.value);
    };

    const modalChangeActivity = () => {
      isModalActive.value = !isModalActive.value;
    };
    onMounted(() => {
      dispatch('axiosGetContest', getableData.value);
      dispatch('axiosMembersGetData', getableData.value);
    });

    const columnsTransactions = computed(() => [
      {
        title: i18n.t('contests.table.place'),
        dataIndex: 'place',
        key: 'place',
      },
      {
        title: i18n.t('contests.table.name'),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: i18n.t('contests.table.amount'),
        dataIndex: 'amount',
        key: 'amount',
      },

      {
        title: i18n.t('contests.table.created_at'),
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: '',
        width: 0,
      },
    ]);

    const dataSourceTransactions = computed(() =>
      membersData.value
        ? membersData.value.map((transaction, key) => {
            const { attributes, id } = transaction;
            const { created_at, amount, amount_currency, name, place } = attributes;

            return {
              key: key,
              id,
              name,
              place,
              created_at: i18n.d(created_at, 'short'),
              amount: i18n.n(+amount, 'decimal') + ' ' + amount_currency,
            };
          })
        : [],
    );

    return {
      i18n,
      contestData,
      columnsTransactions,
      membersData,
      dataSourceTransactions,
      pageNum,
      pageSize,
      faSort,
      faSortUp,
      faSortDown,
      order,
      linkValue,
      membersMeta,
      isModalActive,
      buyTraitModalVisible,
      onChangePage,
      modalChangeActivity,
    };
  },
};
export default TradingAccountInfoPage;
</script>
<style scoped lang="scss"></style>
